import type {
  EditorScriptFlowAPI,
  WidgetBuilder,
} from '@wix/yoshi-flow-editor';
import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';

import { ElementId, Experiment, GfppAction } from '../../constants';

import { MembersAreaContext } from '../../types/public-apis';
import { getSettingsPageRef } from '../../editor/services/page';

type ComponentBuilderFn = Parameters<
  WidgetBuilder['configureConnectedComponents']
>[1];

const COLLAPSABLE_ELEMENT_IDS = [
  ElementId.HeaderContainer,
  ElementId.MenuContainer,
];

type ConfigureWidgetGFPPProps = {
  builder: WidgetBuilder;
  flowAPI: EditorScriptFlowAPI;
  membersAreaContext: MembersAreaContext;
};

const configureWidgetGFPP = ({
  builder,
  flowAPI,
  membersAreaContext,
}: ConfigureWidgetGFPPProps) => {
  const { t } = flowAPI.translations;
  const isMembersAreaV2Context = membersAreaContext === MembersAreaContext.V2;
  const isMembersAreaV3Context = membersAreaContext === MembersAreaContext.V3;
  const shouldAddUnifiedManagerGFPP = flowAPI.experiments.enabled(
    Experiment.EnableUnifiedManager,
  );

  if (shouldAddUnifiedManagerGFPP) {
    builder.gfpp().set('mainAction1', {
      label: t('app.profile-page.unified-manager.gfpp'),
      actionId: GfppAction.OpenUnifiedManagerPanel,
    });
  }

  builder.gfpp().set('help', {
    id: isMembersAreaV2Context
      ? '1216e503-61fb-479f-a318-9af886dd2769'
      : 'a26581e1-7f77-4094-b043-ee74b8c2c12a',
  });
  builder.configureConnectedComponents(
    'horizontalMenu1',
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder.gfpp().set('help', {
        id: isMembersAreaV2Context
          ? 'ac4ace90-6edf-40c3-91d2-707d554e9e07'
          : '4c8ba651-aa3f-45c2-82ae-abf3f856b3e6',
      });
      if (shouldAddUnifiedManagerGFPP) {
        connectedComponentsBuilder.gfpp().set('mainAction1', {
          label: t(
            'app.settings.manage.menu.panel.manage-and-navigate-renaming.cta',
          ),
          actionId: GfppAction.OpenManageAndNavigatePanel,
        });
        connectedComponentsBuilder.gfpp().set('mainAction2', {
          label: t('app.profile-page.horizontal-menu.unified-manager.gfpp'),
          actionId: GfppAction.OpenUnifiedManagerPanel,
        });
      }
    },
  );

  builder.configureConnectedComponents(
    'expandableMenu1',
    (connectedComponentsBuilder) => {
      if (isMembersAreaV3Context) {
        connectedComponentsBuilder.gfpp().set('help', {
          id: '667fd515-1ffb-4879-9fa9-bad9a145aef6',
        });
      }
      if (shouldAddUnifiedManagerGFPP) {
        connectedComponentsBuilder.gfpp().set('mainAction1', {
          label: t(
            'app.settings.manage.menu.panel.manage-and-navigate-renaming.cta',
          ),
          actionId: 'OpenManageAndNavigatePanel',
        });
        connectedComponentsBuilder.gfpp().set('mainAction2', {
          label: t('app.profile-page.expandable-menu.unified-manager.gfpp'),
          actionId: GfppAction.OpenUnifiedManagerPanel,
        });
      }
    },
  );

  builder.gfpp('mobile').set('mainAction1', { behavior: 'HIDE' });
  builder.gfpp().set('widgetPlugins', {
    behavior: 'HIDE',
  });
};

const configurePageWidget = (builder: WidgetBuilder) => {
  builder.behavior().set({ removable: false, duplicatable: false });
};

const configureComponentAsCollapsable: ComponentBuilderFn = (builder) => {
  builder.behavior().set({ preventHide: false });
};

export const getWidgetManifest: GetWidgetManifestFn = async (
  widgetBuilder,
  editorSDK,
  flowAPI,
) => {
  configurePageWidget(widgetBuilder);
  COLLAPSABLE_ELEMENT_IDS.forEach((elementId) => {
    widgetBuilder.configureConnectedComponents(
      elementId,
      configureComponentAsCollapsable,
    );
  });

  const settingsPageRef = await getSettingsPageRef(editorSDK);

  configureWidgetGFPP({
    builder: widgetBuilder,
    flowAPI,
    membersAreaContext: settingsPageRef
      ? MembersAreaContext.V3
      : MembersAreaContext.V2,
  });
};
